<template>
    <form class="panel relative">
        <slot name="contextMenu"></slot>
        
        <div class="flex">
            <a 
                @click.prevent="$emit( 'productEdit', originalProduct )"
                class="flex-none bg-gray-300 w-24 h-24 flex items-center justify-center mr-10">
                
                <img 
                    v-if="originalProduct.coverUrl"
                    :src="originalProduct.coverUrl" 
                    alt="Product Image"
                    class="w-full h-full object-cover rounded"
                />
            </a>
            
            <div 
                class="flex-grow pr-12 flex flex-col justify-center"
                @click.prevent="$emit( 'productEdit', originalProduct )">
                
                
                <div class="">
                    <div class="mb-4">
                        <h2 
                            v-if="!editName"
                            @click.prevent="onEditName()"
                            v-html="offerProduct.name"
                            class="text-black leading-tight border-b border-transparent hover:border-gray-400 border-dashed pb-1"
                        />
                        <div 
                            v-else
                            class="relative">
                            
                            <input 
                                type="text"
                                v-model="offerProduct.name"
                                style="padding-right: 5rem;"
                            />
                            <div class="absolute top-0 right-0 mt-3 mr-4 flex">
                                <i 
                                    class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                    @click="offerProduct.name = originalProduct.name; editName = false; "
                                    title="Reset"
                                />
                                <i 
                                    class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                    @click="editName = false"
                                    :title="$t('offers.doneEditing')"
                                />
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    <div class="mb-4">
                        <div 
                            v-if="!editDescription"
                            @click.prevent="onEditDescription()"
                            v-html="offerProduct.description"
                            class="hover:text-black border-b border-transparent hover:border-gray-400 border-dashed pb-1"
                        />
                        <div 
                            v-else
                            class="relative">
                            
                            <InputMultiline 
                                v-model="offerProduct.description"
                            />
                            <div class="absolute bottom-0 right-0 mb-4 mr-4 flex">
                                <i 
                                    class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                    @click="offerProduct.description = originalProduct.description; editDescription = false;"
                                    title="Reset"
                                />
                                <i 
                                    class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                    @click="editDescription = false"
                                    :title="$t('offers.doneEditing')"
                                />
                            </div>
                        </div>
                    </div>
                        
                    
                    
                </div>
                
                <div 
                    class="my-1"
                    :class="originalProduct.price != offerProduct.price ? 'line-through' : ''">
                    
                    {{currencyFormat( originalProduct.price )}}
                    
                    <span v-if="offer.show.vatCalculation">
                        {{$t('products.excludedVat')}}
                    </span>
                    
                    
                    <!-- {{originalProduct.vatPercent}}  -->
                </div>
                <div class="text-red-500" v-if="originalProduct.deletedAt">
                    {{$t('general.deleted')}} {{ originalProduct.deletedAt | moment('from') }}
                </div>
                <div class="text-gray-500" v-else>
                    <span v-if="originalProduct.createdAt != originalProduct.updatedAt">{{$t('general.updated')}} {{ originalProduct.updatedAt | moment('from') }}</span>
                    <span v-else>{{$t('general.created')}} {{ originalProduct.createdAt | moment('from') }}</span>
                </div>
            </div>
            
        </div>
        
        <div class="-mx-8 mt-4 -mb-8 p-8 bg-gray-100 grid gap-4 grid-cols-2 md:grid-cols-4">
            <div>
                <label class="mt-0">
                    {{$t('offers.quantity')}}
                </label>
                <input 
                    type="number" 
                    class="text-center"
                    style="margin-bottom: 0"
                    :placeholder="$t('offers.quantity')"
                    min="1"
                    v-model.number="offerProduct.qty"
                />
            </div>
            <div>
                <label class="mt-0">
                    {{$t('offers.unitPrice')}} {{account.locale.currency}}
                </label>
                <input 
                    type="number"
                    class="text-center"
                    style="margin-bottom: 0"
                    v-model.number="offerProduct.price"
                />
            </div>
            <div class="">
                <label class="mt-0">
                    {{$t('offers.discount')}}
                </label>
                <input 
                    type="number" 
                    class="text-center"
                    style="margin-bottom: 0"
                    :placeholder="$t('offers.discount')"
                    min="0"
                    max="100"
                    v-model.number="offerProduct.discount"
                />
            </div>
            <div>
                <label class="mt-0">
                    {{$t('products.amount')}}
                    <span v-if="offer.show.vatCalculation">
                        {{$t('products.excludedVat')}}
                    </span>
                </label>
                <input 
                    type="text"
                    class="text-center"
                    style="margin-bottom: 0"
                    :value="currencyFormat( ( offerProduct.price  - (offerProduct.price * offerProduct.discount / 100)) * offerProduct.qty , false)"
                    disabled
                    readonly
                />
            </div>
            
            <div 
                v-if="offer.show.vatCalculation" 
                class="md:col-start-3">
                
                <label class="mt-0">
                    {{$t('products.vat')}} ({{ vatPercent + '%' }})
                </label>
                <input 
                    type="text"
                    class="text-center"
                    style="margin-bottom: 0"
                    :value="currencyFormat( vat , false)"
                    disabled
                    readonly
                />
            </div>
            <div 
                v-if="offer.show.vatCalculation" 
                class="">
                
                <label class="mt-0">
                    {{$t('products.amount')}} {{$t('products.includedVat')}}
                </label>
                <input 
                    type="text"
                    class="text-center"
                    style="margin-bottom: 0"
                    :value="currencyFormat( priceTotalIncludedVat , false)"
                    disabled
                    readonly
                />
            </div>
            
            
        </div>
        
        <div 
            v-if="originalProduct.isDateProduct" 
            class="-mx-8 mt-4 -mb-8 p-8 bg-gray-100">
            
            <div 
                v-for="(date,dateIndex) in offerProduct.dates"
                :key="'date_'+dateIndex"
                class="h-12 flex mb-2">
                
                <DatePicker 
                    class="flex-grow"
                    v-model="date.date"
                    :inline="false"
                    format="YYYY-MM-DD"
                    value-type="format"
                    :lang="{formatLocale: { firstDayOfWeek: 1}}"
                />
                
                <div class="flex-none mt-3 ml-3">
                    <i 
                        class="fas fa-trash"
                        :title="$t('general.remove')"
                        @click="offerProduct.dates.splice(dateIndex, 1)"
                    />
                </div>
            </div>
            
            <div 
                class="button"
                @click="offerProduct.dates.push({
                    date: null,
                })">
                
                <i class="far fa-calendar-alt mr-2" />
                {{$t('offers.addDate')}}
            </div>
        </div>
    </form>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
    components: {
        DatePicker,
    },
    
    props: {
        originalProduct: {
            type: Object,
            required: true,
        },
        
        offerProduct: {
            type: Object,
            required: true,
        },
    
        offer: {
            type: Object,
            required: true,
        },
    },
    
    computed: {
        priceTotalExcludedVat(){
            return ( this.offerProduct.price  - (this.offerProduct.price * this.offerProduct.discount / 100)) * this.offerProduct.qty;
        },
        
        vatPercent(){
            return this.originalProduct.vatPercent || 0;
        },
        
        vat(){
            return this.priceTotalExcludedVat * this.vatPercent / 100;
        },
        
        priceTotalIncludedVat(){
            return this.priceTotalExcludedVat + this.vat;
        },
    },
    
    data(){
        return {
            editName: false,
            editDescription: false,
        }
    },
    
    methods: {
        onEditName(){
            this.editName = true;
            // if (!this.offerProduct.name) {
            //     this.offerProduct.name = this.originalProduct.name;
            // }
        },
        onEditDescription(){
            this.editDescription = true;
            // if (!this.offerProduct.description) {
            //     this.offerProduct.description = this.originalProduct.description;
            // }
        },
    },
    
}
</script>